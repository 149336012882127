<template>
  <div>
    <b-card>
      <validation-observer
          ref='form'
          v-slot='{invalid}'
      >
        <!--        <b-card v-if='!isDataLoaded'>-->
        <!--          <div class='text-center text-success my-2'>-->
        <!--            <b-spinner class='align-middle' />-->
        <!--          </div>-->
        <!--        </b-card>-->
        <b-form
            novalidate
            @submit.prevent='onSubmit'
        >
          <b-row class='align-items-end'>
            <b-col
                cols='12'
            >
              <LocaleTabs
                  :errors='formErrors'
                  name='title'
              >
                <template
                    v-for='(i,k,idx) in languages'
                    :slot='`lang${idx}`'
                    slot-scope='slotScope'
                >
                  <ValidationProvider
                      :key='idx'
                      v-slot='{ errors }'
                      :class="slotScope.selectedTab!==idx?'hidden':''"
                      :vid='`title.${k}`'
                      :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      rules='required'
                  >
                    <b-form-group
                        :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                          v-model='form.title[k]'
                          :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <!-- checkbox -->
            <b-col cols='12'>
              <b-card-text class='mb-0'>
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                  v-model='form.status'
                  checked='true'
                  value='1'
                  unchecked-value='0'
                  name='check-button'
                  switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                  type='reset'
                  variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [formMixin],
  data() {
    return {
      // types: [],
      form: {
        title: {
          en: null,
          ar: null,
        },
        // image: null,
        status: 1,
      },

      isDataLoaded: false,
      statuses: [],
    }
  },

  created() {
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
          .then(res => {
            const { data } = res.data
            this.form = {
              title: data.title,
              status: data.status,

            }
            this.isDataLoaded = true
          })
    },

  },

}
</script>
